<template>
  <div class="container">
    <div class="static-page">
      <h1>Оплата</h1>
      <div class="payment">
        <p>Оплата банковскими картами осуществляется через систему PayOnline.</p>

        <p>К оплате принимаются карты VISA, MasterCard, Мир.</p>

        <p>&emsp;Услуга оплаты через интернет осуществляется в соответствии с Правилами международных платежных систем
          Visa,
          MasterCard и Мир на принципах соблюдения конфиденциальности и безопасности совершения платежа, для чего
          используются самые современные методы проверки, шифрования и передачи данных по закрытым каналам связи.
          Ввод данных банковской карты осуществляется на защищенной платежной странице PayOnline.</p>

        <p>&emsp;На странице для ввода данных банковской карты потребуется ввести номер карты, имя владельца карты,
          срок действия карты, трёхзначный код безопасности (CVV2 для VISA или CVC2 для MasterCard). Все необходимые
          данные пропечатаны на самой карте. Трёхзначный код безопасности — это три цифры, находящиеся на обратной
          стороне карты.</p>

        <p>&emsp;Далее вы будете перенаправлены на страницу банка, выдавшего Вам карту для ввода 3DSecure кода,
          который придет к Вам в СМС. Если 3DSecure код к Вам не пришел, то следует обратится в банк выдавший Вам
          карту.</p>
        <br>

        <p><u>Случаи отказа в совершении платежа:</u></p>

        <p>&emsp;- банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать,
          обратившись в Ваш Банк;</p>
        <p>&emsp;- недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на банковской карте
          Вы можете узнать, обратившись в банк, выпустивший банковскую карту;</p>
        <p>&emsp;- данные банковской карты введены неверно;</p>
        <p>&emsp;- истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой стороне
          карты
          (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Вы можете узнать,
          обратившись в банк, выпустивший банковскую карту;</p>

        <br>

        <p><u>Процедура возврата товара регламентируется статьей 26.1 федерального закона «О защите прав
          потребителей».</u></p>

        <p>&emsp;- потребитель вправе отказаться от товара в любое время до его передачи, а после передачи товара - в
          течение семи дней;</p>
        <p>&emsp;- возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид,
          потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара;</p>
        <p>&emsp;- потребитель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-
          определенные свойства, если указанный товар может быть использован исключительно приобретающим
          его человеком;</p>
        <p>&emsp;- при отказе потребителя от товара продавец должен возвратить ему денежную сумму, уплаченную
          потребителем по договору, за исключением расходов продавца на доставку от потребителя возвращенного
          товара, не позднее чем через десять дней со дня предъявления потребителем соответствующего
          требования;</p>

        <br>

        <p>Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате денежных
          средств», которое высылается по требованию компанией на электронный адрес и оправить его вместе с
          приложением копии паспорта по адресу info@alamat.ru</p>

        <br>

        <p>Возврат денежных средств будет осуществлен на банковскую карту в течение 21 (двадцати одного) рабочего дня со
          дня получения «Заявление о возврате денежных средств» Компанией.</p>

        <br>

        <p>Для возврата денежных средств по операциям проведенными с ошибками необходимо обратиться с письменным
          заявлением и приложением копии паспорта и чеков/квитанций, подтверждающих ошибочное списание. Данное
          заявление необходимо направить по адресу info@alamat.ru</p>

        <br>

        <p>Сумма возврата будет равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных средств
          начинает исчисляться с момента получения Компанией Заявления и рассчитывается в рабочих днях без учета
          праздников/выходных дней.</p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  async mounted() {

  },
  methods: {}
}
</script>
